

.react-calendar__tile {
  min-height: 100px !important;
  height: auto !important;
  padding: 0.5rem !important;
  position: relative;
  border-radius: 12px;
  border: 1px solid rgba(109, 40, 217, 0.2) !important;
  background: rgba(42, 27, 61, 0.2) !important;
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .react-calendar__tile {
    min-height: 80px !important;
    padding: 0.25rem !important;
    font-size: 0.875rem;
  }

  .react-calendar__navigation button {
    font-size: 1rem !important;
    padding: 0.5rem !important;
  }

  .react-calendar__month-view__weekdays {
    font-size: 0.75rem !important;
  }

  .calendar-popup {
    margin: 1rem 0;
    padding: 1rem;
    background: rgba(42, 27, 61, 0.9);
    backdrop-filter: blur(8px);
    border-radius: 16px;
    border: 1px solid rgba(109, 40, 217, 0.2);
  }

  .mobile-week-view + .calendar-popup {
    margin-top: 2rem;
  }

  .tasks-container {
    max-height: 60px;
    margin-top: 0.5rem;
  }
}

.react-calendar__tile:enabled:hover {
  background: rgba(109, 40, 217, 0.2) !important;
  border-color: #6d28d9 !important;
  transform: scale(0.98);
}

.react-calendar__tile--active {
  background: rgba(109, 40, 217, 0.3) !important;
  border-color: #6d28d9 !important;
  box-shadow: 0 0 15px rgba(109, 40, 217, 0.3);
}

.react-calendar__tile.current-day {
  border: 2px solid #6d28d9 !important;
  background: rgba(109, 40, 217, 0.15) !important;
}

.react-calendar__tile.has-flows {
  background: linear-gradient(135deg, rgba(56, 24, 107, 0.1), rgba(0, 0, 0, 0.1)) !important;
}

.react-calendar__navigation {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.react-calendar__navigation button {
  color: white !important;
  font-size: 1.2rem;
  padding: 0.75rem;
  background: rgba(109, 40, 217, 0.1) !important;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.react-calendar__navigation button:enabled:hover {
  background: rgba(109, 40, 217, 0.2) !important;
  transform: scale(0.95);
}

.react-calendar__navigation button:disabled {
  opacity: 0.5;
}

.react-calendar__month-view__weekdays {
  color: #6d28d9 !important;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.tasks-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 0.5rem;
  max-height: 70px;
  overflow-y: auto;
  padding-right: 2px;
}

.tasks-container::-webkit-scrollbar {
  width: 3px;
}

.tasks-container::-webkit-scrollbar-track {
  background: transparent;
}

.tasks-container::-webkit-scrollbar-thumb {
  background: rgba(109, 40, 217, 0.3);
  border-radius: 4px;
}

.calendar-popup {
  background: rgba(42, 27, 61, 0.7);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(109, 40, 217, 0.2);
  border-radius: 16px;
  padding: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.calendar-popup h3 {
  color: #e879f9;
  text-align: center;
  margin-bottom: 1.5rem;
}

@media (max-width: 768px) {
  .react-calendar__tile:active {
    transform: scale(0.95);
  }

  .calendar-popup {
    border-radius: 20px 20px 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    max-height: 60vh;
    overflow-y: auto;
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .calendar-mobile {
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;
  }

  /* Kontener na dni tygodnia */
  .calendar-mobile .react-calendar__month-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Grid z dniami */
  .calendar-mobile .react-calendar__month-view__days {
    display: flex !important;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
    width: 100%;
    padding: 0.5rem 0;
  }

  /* Kafelki dni */
  .calendar-mobile .react-calendar__tile {
    flex: 1 0 13% !important; /* około 7 kafelków w rzędzie z małymi odstępami */
    min-height: 90px !important;
    max-width: 13% !important;
    aspect-ratio: 1;
    border-radius: 16px !important;
    margin: 0;
    padding: 0.25rem !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  /* Nagłówki dni tygodnia */
  .calendar-mobile .react-calendar__month-view__weekdays {
    display: flex !important;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
  }

  .calendar-mobile .react-calendar__month-view__weekdays__weekday {
    flex: 1 0 13% !important;
    text-align: center;
    padding: 0.5rem 0;
    max-width: 13% !important;
  }

  /* Nawigacja */
  .calendar-mobile .react-calendar__navigation {
    background: rgba(42, 27, 61, 0.8);
    backdrop-filter: blur(12px);
    padding: 1rem;
    border-radius: 16px;
    margin-bottom: 1rem;
  }

  .calendar-mobile .react-calendar__navigation button {
    font-size: 0.9rem !important;
    padding: 0.5rem !important;
    background: transparent !important;
  }

  /* Dni tygodnia */
  .calendar-mobile .react-calendar__month-view__weekdays {
    background: rgba(42, 27, 61, 0.5);
    padding: 0.5rem;
    border-radius: 12px;
    margin: 0.5rem 0;
    font-size: 0.7rem !important;
  }

  .calendar-mobile .react-calendar__month-view__weekdays__weekday {
    padding: 0.5rem 0;
    text-transform: uppercase;
    font-weight: 600;
  }

  /* Kafelki dni */
  .calendar-mobile .react-calendar__tile {
    min-height: 100px !important;
    aspect-ratio: 1;
    border-radius: 16px !important;
    margin: 2px;
    padding: 0.25rem !important;
    background: rgba(42, 27, 61, 0.7) !important;
    backdrop-filter: blur(8px);
    border: 1px solid rgba(109, 40, 217, 0.2) !important;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  /* Aktywny dzień */
  .calendar-mobile .react-calendar__tile--active {
    background: rgba(109, 40, 217, 0.3) !important;
    border: 2px solid #6d28d9 !important;
    box-shadow: 0 0 20px rgba(109, 40, 217, 0.3);
    transform: scale(1.02);
  }

  /* Dzisiejszy dzień */
  .calendar-mobile .react-calendar__tile.current-day {
    background: linear-gradient(135deg, rgba(109, 40, 217, 0.2), rgba(76, 29, 149, 0.2)) !important;
    border: 2px solid #6d28d9 !important;
  }

  /* Container na zadania w kafelku */
  .calendar-mobile .tasks-container {
    margin-top: auto;
    max-height: 60%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  /* Pojedyncze zadanie w kafelku */
  .calendar-mobile .task-item {
    background: rgba(109, 40, 217, 0.2);
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 0.65rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Pop-up z szczegółami dnia */
  .calendar-mobile .calendar-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(42, 27, 61, 0.95);
    backdrop-filter: blur(12px);
    border-radius: 24px 24px 0 0;
    padding: 1.5rem;
    z-index: 1000;
    box-shadow: 0 -8px 32px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
  }

  .calendar-mobile .calendar-popup.hidden {
    transform: translateY(100%);
  }

  /* Wskaźnik przewijania */
  .calendar-mobile .calendar-popup::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;
  }

  /* Animacje dotknięcia */
  .calendar-mobile .react-calendar__tile:active {
    transform: scale(0.95);
    transition: transform 0.1s ease-in-out;
  }
}

/* Dodatkowe style dla lepszej czytelności */
.calendar-mobile .react-calendar__month-view__days__day--weekend {
  color: #e879f9 !important;
}

.calendar-mobile .react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.5;
}

/* Mobile Week View Styles */
.mobile-week-view {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0.5rem;
}

.mobile-day-card {
  background: rgba(42, 27, 61, 0.7);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(109, 40, 217, 0.2);
  border-radius: 16px;
  padding: 1rem;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.mobile-day-card.current-day {
  background: linear-gradient(135deg, rgba(109, 40, 217, 0.2), rgba(76, 29, 149, 0.2));
  border: 2px solid #6d28d9;
}

.mobile-day-card.selected-day {
  background: rgba(109, 40, 217, 0.3);
  border: 2px solid #6d28d9;
  box-shadow: 0 0 20px rgba(109, 40, 217, 0.3);
  transform: scale(1.02);
}

.day-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(109, 40, 217, 0.2);
}

.day-name {
  font-weight: 600;
  color: #e879f9;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.day-number {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

.day-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
}

.day-content::-webkit-scrollbar {
  width: 3px;
}

.day-content::-webkit-scrollbar-track {
  background: transparent;
}

.day-content::-webkit-scrollbar-thumb {
  background: rgba(109, 40, 217, 0.3);
  border-radius: 4px;
}

/* Animacje dla mobile */
@media (max-width: 768px) {
  .mobile-day-card:active {
    transform: scale(0.98);
  }
}

/* Poprawki dla sidebara */
@media (max-width: 768px) {
  .mobile-menu-open {
    width: 100% !important;
  }

  .mobile-menu-open .sidebar-content {
    align-items: center;
    padding: 0 1rem;
  }

  .mobile-menu-open .info-tooltip {
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 400px;
  }
}

/* Poprawki dla kalendarza */
.calendar-section {
  padding: 2rem;
  width: 100%;
  margin: 0 auto;
}

.react-calendar {
  background: transparent !important;
  border: none !important;
  font-family: inherit;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .react-calendar {
    width: 100% !important;
    margin-left: 0;
    padding: 0;
  }
}