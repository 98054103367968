body {
  font-family: "Roboto", sans-serif;
}
.bg-gray-900 {
  background-color: #15061a;
}
.bg-gray-800 {
  background-color: #1e1134;
}
.draggable-item {
  background-color: #2a1b3d;
  color: #ffffff;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  align-items: center;
}
.draggable-item:hover {
  opacity: 0.8;
}
.drop-zone {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #1e1134;
  flex-grow: 1;
  border-radius: 0.5rem;
}
.custom-node {
  /* background: linear-gradient(to right, #a855f7, #ec4899, #f87171); */
  color: #ffffff;
  padding: 1rem 2rem;
  border-radius: 1rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
  background 0.3s ease-in-out;
  position: relative;
  animation: pulse 2s infinite;
  text-align: center;
  font-size: 1.2rem;
  font-family: "Arial", sans-serif;
}

.custom-node::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #f87171, #ec4899, #a855f7);
  border-radius: 1rem;
  z-index: -1;
  filter: blur(8px);
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

.custom-node:hover::before {
  opacity: 0.7;
}

.custom-node:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.platform-card {
  transition: transform 0.3s ease-in-out;
  background-color: #2a1b3d;
}
.platform-card:hover {
  transform: scale(1.05);
}
.text-white {
  color: #ffffff;
}
.text-gray-400 {
  color: #cccccc;
}
.text-sm {
  font-size: 0.875rem;
}
.cursor-pointer {
  cursor: pointer;
}
.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}
.bg-gradient-to-r {
  background: linear-gradient(to right, #ff6f61, #de6262);
}
.project-sidebar {
  width: 100%;
  padding: 1rem;
  background-color: #2a1b3d;
  overflow-y: auto;
  border-right: 1px solid #6d28d9;
  max-width: 100%;
}
.project-item {
  background-color: #351c4d;
  color: white;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}
.project-item .thumbnail {
  width: 50px;
  height: 50px;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
  object-fit: cover;
}
.project-item button {
  margin-left: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}
.project-item button.bg-blue-500 {
  background-color: #6d28d9;
}
.project-item button.bg-red-500 {
  background-color: #ef4444;
}
.new-project-btn {
  background-color: #3b82f6;
  color: white;
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 0.5rem;
}

.form-section {
  background-color: #2a1b3d;
  padding: 2rem;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}
.form-section input,
.form-section select,
.form-section button {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: none;
}
.form-section input {
  background-color: #351c4d;
  color: white;
}
.form-section select {
  background-color: #351c4d;
  color: white;
}
.form-section button {
  background-color: #6d28d9;
  color: white;
  cursor: pointer;
}
.form-section button:hover {
  background-color: #5b21b6;
}

/* React Flow background and grid colors */
.drop-zone {
  background-color: #1e1134 !important; /* Ten sam kolor co bg-gray-800 */
}

/* Style dla siatki React Flow */
.react-flow__background {
  background-color: #15061a !important; /* Ten sam kolor co bg-gray-900 */
}

/* Style dla węzłów React Flow */
.react-flow__node {
  background-color: #2a1b3d !important; /* Ten sam kolor co inne elementy UI */
  border-color: #6d28d9 !important; /* purple-700 */
}

/* Style dla połączeń React Flow */
.react-flow__edge path {
  stroke: #6d28d9 !important; /* purple-700 */
}

/* Style dla handleów połączeń */
.react-flow__handle {
  background-color: #6d28d9 !important; /* purple-700 */
  border-color: #4c1d95 !important; /* purple-900 */
}

/* Hover state dla elementów React Flow */
.react-flow__node:hover {
  background-color: #351c4d !important;
}

/* Background pattern */
.react-flow__background-pattern {
  color: rgba(109, 40, 217, 0.3) !important; /* purple-700 z opacity */
}