/* src/components/CustomNodes.css */
@keyframes pulse-success {
  0% {
    box-shadow: 0 0 3px 0 #4caf50;
  }
  25% {
    box-shadow: 0 0  6px 1px #4caf50;
  }
  50% {
    box-shadow: 0 0  9px  2px #4caf50;
  }
  75% {
    box-shadow: 0 0  6px 1px #4caf50;
  }
  100% {
    box-shadow: 0 0 3px 0 #4caf50;
  }
  }
  
  @keyframes pulse-reject {
    0% {
      box-shadow: 0 0 3px 0 #af4c4c;
    }
    25% {
      box-shadow: 0 0  6px 1px #af4c4c;
    }
    50% {
      box-shadow: 0 0  9px  2px #af4c4c;
    }
    75% {
      box-shadow: 0 0  6px 1px #af4c4c;
    }
    100% {
      box-shadow: 0 0 3px 0 #af4c4c;
    }
  }
  
  .pulse-success {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: transparent;
    will-change: box-shadow;
    z-index: 999;
    animation: pulse-success 0.6s ease-in-out;
  }
  
  .pulse-reject {
    animation: pulse-reject 0.6s ease-in-out;
  }
