body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Custom Toggle Switch */
  .toggle-container {
    display: flex;
    justify-content: flex-start;
  }

  .toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .toggle-slider {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    border-radius: 9999px;
    background-color: #ccc;
    transition: all 0.4s;
  }

  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: all 0.4s;
    border-radius: 9999px;
  }

  input:checked + .toggle-slider {
    background: linear-gradient(to right, #A875C5, #E57B9B, #F54932);
  }

  input:checked + .toggle-slider:before {
    transform: translateX(20px);
  }

  .toggle-slider:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
